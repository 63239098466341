html {
  background-color: #eef2f4;
  --primary-color: #6e7a58;
  --highlight-color: #4098df;
  --highlight-text-color: white;
  --sidebar-dark-color: #3d4b5b;
  --sidebar-light-color: #7c919f;

  --header-height: 10vh;

  font-size: 11pt;
}

body {
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.content-wrapper {
  display: flex;
}

.non-nav-content {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  max-height: 100vh;
  overflow: scroll;
}

.body-wrapper {
  padding: 1%;
  height: 90vh;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}
.MuiListItem-root:focus,
.MuiListItem-root:hover {
  filter: brightness(0.9);
}

nav {
  background-color: white;
  transition: max-width 0.2s, transform 0.2s;
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.28);
}
nav > ul > div {
  padding: 10px;
  font-size: 14px;
}
nav.open {
  min-width: fit-content;
  max-width: 50%;
  transform: translateX(0%);
}
nav.closed {
  transform: translateX(-100%);
  min-width: 0;
  max-width: 0;
}
.block-content { display: none; position: absolute; }
@media screen and (max-width: 1007px) {
  nav {
    position: absolute;
    z-index: 2;
    top: 10vh;
    min-height: calc(100vh - var(--header-height));
    max-height: calc(100vh - var(--header-height));
  }
  nav.open + .block-content {
    display: block;
    inset: 0;
    transition: background 0.2s;
    background: rgba(0, 0, 0, 0.27);
    backdrop-filter: blur(2px);
    z-index: 1;
  }
}

nav .logo {
  margin: 20px;
}

.block-content.always {
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  transition: background 0.2s;
  background: rgba(0, 0, 0, 0.27);
  backdrop-filter: blur(2px);
  z-index: 100;
}

header {
  height: var(--header-height);
  background-color: white;
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 2;
}
header > .user-info {
  display: flex;
  align-items: center;
}
header > .sidebar-toggle {}

.table-wrapper {
  margin: 40px auto;
  max-width: calc(100% - 40px);
}

.table-wrapper > .addRowButton-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.search-wrapper {
  position: relative;
  color: rgba(0, 0, 0, 0.5);
  --search-height: 20px;
  --left-padding: 5px;
  --vertical-padding: 5px;
}
.search-wrapper > svg {
  position: absolute;
  height: var(--search-height);
  margin: 0;
  padding: var(--vertical-padding) 0;
  padding-left: var(--left-padding);
}
.search-wrapper > input {
  padding: var(--vertical-padding) 0;
  padding-left: calc(var(--search-height) + var(--left-padding) + 5px);
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  font-weight: 800;
  color: rgba(0, 0, 0, 0.5);
  height: var(--search-height);
}
.search-wrapper > input:hover,
.search-wrapper > input:focus {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

thead {
  background-color: #f3f8fa;
  text-transform: uppercase;
}
thead tr:nth-child(1) {
}

tbody > tr > th:last-child,
thead > tr > th:last-child {
  text-align: right;
}
tbody tr th img.image-logo {
  padding: 10px;
  max-height: 100px;
  max-width: 200px;
  border-radius: 5px;
}
tbody tr th img.image-logo {
  background: var(--primary-color);
}

button {
  height: 2rem;
  margin: 5px;
  background-color: white;
  outline: 1px solid grey;
  border: none;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  font-weight: 1000;
}

.input-wrapper > img {
  max-height: 100px;
  max-width: 100px;
  margin: 10px;
}

button.primary {
  background-color: var(--highlight-color);
  color: var(--highlight-text-color);
  outline: none;
  border: none;
  padding: 0 40px;
}
button.right {
  display: block;
  margin-left: auto;
}

.row-buttons {
  width: max-content;
}
.row-buttons button {
  border: none;
  outline: none;
  color: #c1c1c5;
  background-color: transparent;
}

button:hover::after,
button:focus::after {
  content: '';
  cursor: pointer;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

form {
  width: 100%;
}
.filter-panel > .options,
form > div {
  display: grid;
  --width: 600px;
  --col-width: 500px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--col-width), 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 5px;
}

.filter-panel {
  background-color: white;
  margin: 10px 0 10px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.5);
  --time: 0.5s;
  transition: max-height var(--time), padding var(--time);
  box-sizing: border-box;
  overflow: hidden;
}
.filter-panel.open {
  max-height: 500px;
  overflow: scroll;
}
.filter-panel.closed {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.dialog .heading,
.filter-panel .heading {
  display: flex;
  justify-content: space-between;
}

.loading {
  width: fit-content;
  margin: auto;
}

.table-label { margin-bottom: 20px; }

.table-title-outer {
  text-align: left;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-title-inner {
  width: fit-content;
  position: sticky;
  left: 16px;
  color: white;
}
.table-add-button-wrapper {
  width: fit-content;
  position: sticky;
  right: 16px;
}

.add-row-error {
  min-height: 50px;
}

.OwnMuiSvgIcon {
    width:24px;
    height:24px;
    fill: currentColor;
}