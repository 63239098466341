.content-box {
  background-color: white;
  border-radius: 15px;
  width: fit-content;
  max-width: 100%;
  margin: 40px auto;
  padding: 40px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}
