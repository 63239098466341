.input-wrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1 1 100%;
  position: relative;
}

.input-wrapper > label.file {
  max-width: calc(133% - 32px);
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  line-height: 1.4375em;
  font-size: 1rem;
  transform-origin: top left;
  transform: translate(14px, -9px) scale(0.75);
}

.input-wrapper > select,
.input-wrapper > input {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  line-height: 1.4375em;
  min-height: 1.4375em;
  padding: 16.5px 14px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}
.input-wrapper > input:placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.input-wrapper > select,
.input-wrapper > input:not([type='checkbox']) {
  outline: 1px solid rgba(0, 0, 0, 0.23);
}

.input-wrapper > select:focus,
.input-wrapper > select:hover,
.input-wrapper > input:focus,
.input-wrapper > input:hover {
  outline: 1px solid black;
}

/*
input:not(blank):invalid {
  outline: 2px solid red;
}
*/
